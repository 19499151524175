import { isArray } from 'lodash'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import qs from 'qs'
import { Dispatch, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AMPLIFY_USER_POOL_API_CLIENT_ID, COGNITO_OAUTH_DOMAIN, SITE_URL } from 'src/config/config'
import { apolloSdk } from 'src/graphql/apolloSdk'
import { showToast } from 'src/store/actions/toastAction'

const SSOStartPage: NextPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<any> = useDispatch()
  const router = useRouter()
  const { query, isReady } = router
  useEffect(() => {
    if (!isReady) {
      return
    }

    if (query.company) {
      const getPremiumOrganizations = async (company: string) => {
        const { premiumOrganizations } =
          (await apolloSdk.getPremiumOrganizationsByDomainEmail({ domainEmail: company })) || []
        if (isArray(premiumOrganizations?.data)) {
          if (premiumOrganizations?.data.length == 0) {
            dispatch(
              showToast(
                'The domain that you`ve entered doesn`t match a registered account. Please try again or contact us at info@legaltechnology.com for assistance.',
                'error',
              ),
            )
            return
          }
        } else {
          dispatch(showToast('Network Error', 'error'))
          return
        }
        try {
          const _query = qs.stringify({
            redirect_uri: `${SITE_URL}/api/sso/callback/`,
            response_type: 'code',
            client_id: AMPLIFY_USER_POOL_API_CLIENT_ID,
            identity_provider: query.company as string,
            scope: 'email openid',
            state: router.asPath,
          })
          const redirectUrl = `https://${COGNITO_OAUTH_DOMAIN}/oauth2/authorize?${_query}`
          window.location.replace(redirectUrl)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // none
        }
      }
      getPremiumOrganizations(query.company as string)
    }
  }, [query, isReady])

  return null
}

export default SSOStartPage
